import { uniq } from 'lodash'
import { useMemo } from 'react'
import { usePermissions } from '~/entities/viewer'

export const usePermissionsPage = () => {
  const {
    canUsersView,
    canUsersUpdate,
    canRolesUpdate,
    canRolesView,
    canSettingsView,
    canInspectionsView,
    canRepairOrdersUpdate,
    canRepairOrdersView,
    canRoadAccidentsView,
  } = usePermissions()

  const notPermissions = useMemo(() => {
    let data: string[] = []

    if (!canRepairOrdersView)
      data = [
        ...data,
        '/repair-orders',
        '/repair-orders/new',
        '/repair-orders/:repairOrderId',
        '/counterparties',
        '/counterparties/new',
        '/counterparties/:counterpartyId',
      ]
    if (!canRepairOrdersUpdate)
      data = [...data, '/repair-orders/new', '/counterparties/new']

    if (!canSettingsView) data = [...data, '/settings/*']

    if (!canUsersView)
      data = [...data, '/settings/employees', '/settings/employees/*']
    if (!canUsersUpdate) data = [...data, '/settings/employees/new']

    if (!canRolesView) data = [...data, '/settings/roles', '/settings/roles/*']
    if (!canRolesUpdate) data = [...data, '/settings/roles/new']

    if (!canInspectionsView)
      data = [...data, '/inspections', '/inspections/:inspectionId/*']

    if (!canRoadAccidentsView)
      data = [...data, '/road-accidents', '/road-accidents/:roadAccidentId']

    return uniq<string>(data)
  }, [
    canRepairOrdersView,
    canRepairOrdersUpdate,
    canSettingsView,
    canUsersView,
    canUsersUpdate,
    canRolesView,
    canRolesUpdate,
    canInspectionsView,
    canRoadAccidentsView,
  ])

  return { notPermissions }
}
