import { GridColDef } from '@mui/x-data-grid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Событие',
    field: 'eventTitle',
    sortable: false,
    width: 180,
  },
  {
    headerName: 'Пользователь',
    field: 'user',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Дата',
    field: 'date',
    sortable: false,
    flex: 1,
  },
]
