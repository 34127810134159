import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { CircularProgress } from '@mui/joy'
import { useCallback, useState } from 'react'
import { Media } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { myPalette } from '~/shared/lib/theme'
import { IconButton } from '~/shared/ui/Buttons'

type OpenThumbProps = {
  url: string
}

export function OpenThumb({ url }: OpenThumbProps) {
  const [isLoading, setIsLoading] = useState<boolean>()

  const handleMediaGet = useCallback(async () => {
    try {
      setIsLoading(true)
      const blob = await Media.getMedia(url)

      window.open(URL.createObjectURL(blob), '_blank')
    } catch (e) {
      logger.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [url])

  return (
    <IconButton tooltipProps={{ title: 'Открыть' }} onClick={handleMediaGet}>
      {isLoading && <CircularProgress size='sm' />}
      {!isLoading && <OpenInNewIcon sx={{ color: myPalette.brand['700'] }} />}
    </IconButton>
  )
}
