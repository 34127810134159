import { TypographyProps, Typography } from '@mui/joy'
import { useMemo } from 'react'
import {
  formatDateForUI,
  formatDateTimeForUI,
  formatMoscowTime,
  formatTimeForUI,
} from '~/shared/lib/date'

type ColumnDateProps = TypographyProps & {
  type: 'dateTime' | 'date' | 'moscowTime' | 'time'
  value: string | undefined | null
}

export function ColumnDate({ type, value, ...props }: ColumnDateProps) {
  const date = useMemo(() => {
    if (!value) return null

    if (type === 'date') return formatDateForUI(value)
    if (type === 'moscowTime') return formatMoscowTime(value)
    if (type === 'dateTime') return formatDateTimeForUI(value)
    if (type === 'time') return formatTimeForUI(value)
  }, [type, value])

  return (
    <Typography
      {...props}
      sx={{
        ...props?.sx,
        textOverflow: 'ellipsis !important',
        overflow: 'hidden !important',
        display: 'inline-block',
      }}
    >
      {date}
    </Typography>
  )
}
