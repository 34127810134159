import * as z from 'zod'

import { CarBrandModel } from '~/shared/api/carBrandModel'
import { ToOneRelation } from '~/shared/api/core'
import { Subdivision } from '~/shared/api/subdivision'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  vin: z.string(),
  status: z.string(),
  plateNumber: z.string(),
  driverFullName: z.string().optional().nullable(),
  extId: z.string(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type CarAttributes = z.infer<typeof attributesSchema>

export type CarWithRelationOption = {
  id: UniqueId
  label: string
  vin: string
  subdivision: string
  brandModel: string
}

export class Car extends ApiModel<typeof schema, CarAttributes> {
  static jsonApiType = 'cars'

  static schema = schema

  getPlateNumber(): string {
    return this.getAttribute('plateNumber')
  }

  getVin(): string {
    return this.getAttribute('vin')
  }

  getExtId(): UniqueId {
    return this.getAttribute('extId')
  }

  getStatus(): string {
    return this.getAttribute('status')
  }

  subdivision(): ToOneRelation<Subdivision, this> {
    return this.hasOne(Subdivision)
  }
  getSubdivision(): Subdivision {
    return this.getRelation('subdivision')
  }

  brandModel(): ToOneRelation<CarBrandModel, this> {
    return this.hasOne(CarBrandModel)
  }
  getBrandModel(): CarBrandModel {
    return this.getRelation('brandModel')
  }

  getPlateNumberOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getPlateNumber(),
    }
  }

  static async fetchPlateNumberOptions(search: string) {
    const response = await Car.where('plateNumber', search || '').get(1)
    return response.getData().map((o) => o.getPlateNumberOption())
  }

  getWithRelationsOption() {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getPlateNumber(),
      vin: this.getVin(),
      subdivision: this.getSubdivision()?.getTitle(),
      brandModel: this.getBrandModel()?.getTitle(),
    }
  }

  static async fetchWithRelationsOptions(search: string) {
    const response = await Car.with('subdivision')
      .with('brandModel')
      .where('plateNumber', search || '')
      .get(1)
    return response.getData().map((o) => o.getWithRelationsOption())
  }
}
