import { AccordionGroup } from '@mui/joy'
import { GridColDef } from '@mui/x-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { Audit } from '~/shared/api'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { AsyncGrid, FetchRowsFn, GridCell } from '~/shared/ui/AsyncGrid'
import { columns } from './columns'
import { eventEnumLabels } from './constants'
import { ExpandButton } from './ExpandButton'
import { Row } from './Row'
import { GridRow as GridRowType } from './types'

type ListProps = {
  auditableId: UniqueId
}

export const List = ({ auditableId }: ListProps) => {
  const [openedRows, setOpenedRows] = useState<GridRowType[]>([])

  const fetchRows = useCallback<FetchRowsFn<GridRowType>>(
    async (page, pageSize) => {
      const response = await Audit.limit(pageSize)
        .with('user')
        .where('auditableId', auditableId)
        .get(page)

      const data = response.getData()
      const rows = data.map((audit) => ({
        ...audit.getAttributes(),
        id: audit.getApiId() as string,
        date: formatDateTimeForUI(audit.getCreatedAt()),
        user: audit.getUser()?.getName(),
        eventTitle: eventEnumLabels[audit.getEvent()],
        changes: Object.entries(audit.getChanges()).map(([field, change]) => ({
          ...change,
          field,
        })),
      }))

      /*
       * Coloquent не дает метода, чтобы получить объект meta,
       * поэтому в данном случае получаем оригинальный ответ и достаем мету из него
       * TODO: Реализовать лучший способ получения метаданных
       * */
      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [auditableId],
  )

  const handleOpenedRowSet = useCallback((row: GridRowType) => {
    setOpenedRows((openedRows) =>
      openedRows.find((openedRow) => openedRow.id === row.id)
        ? openedRows.filter((openedRow) => openedRow.id !== row.id)
        : [...openedRows, row],
    )
  }, [])

  const openRowColumn: GridColDef<GridRowType> = useMemo(
    () => ({
      headerName: 'Развернуть',
      field: 'openHistory',
      width: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <ExpandButton onClick={() => handleOpenedRowSet(row)} />
      ),
    }),
    [handleOpenedRowSet],
  )

  const components = useMemo(
    () => ({
      Row,
      Cell: GridCell,
    }),
    [],
  )

  const componentsProps = useMemo(
    () => ({
      row: {
        openedRows,
      },
    }),
    [openedRows],
  )

  return (
    <AccordionGroup sx={{ '.MuiDataGrid-row': { border: 'none !important' } }}>
      <AsyncGrid<GridRowType>
        gridKey={`history-changes-list-${auditableId}`}
        fetchRows={fetchRows}
        columns={[openRowColumn, ...columns]}
        memoryLocal
        components={components}
        componentsProps={componentsProps}
        hideHeader
        disableVirtualization
      />
    </AccordionGroup>
  )
}
