import { useStore, useStoreMap, useGate } from 'effector-react'
import { Form, repairOrderModel } from '~/entities/RepairOrder'
import { usePermissions } from '~/entities/viewer'
import { formSubmitted, Gate, $repairOrder, repairOrderUpdateFx } from './model'

type EditRepairOrderProps = {
  repairOrderId: string
}

export function EditRepairOrder({ repairOrderId }: EditRepairOrderProps) {
  useGate(Gate, { repairOrderId: repairOrderId })
  const { canRepairOrdersUpdate } = usePermissions()

  const isLoading = useStore(repairOrderModel.requestFx.pending)
  const isSaving = useStore(repairOrderUpdateFx.pending)

  const defaultValues = useStoreMap($repairOrder, (repairOrder) => {
    const car = repairOrder?.getCar()
    const counterparty = repairOrder?.getCounterparty()
    const repairTypes = repairOrder?.getRepairTypes()
    const roadAccidents = repairOrder?.getRoadAccidents()

    return {
      ...repairOrder?.getAttributes(),
      userName: repairOrder?.getUser().getName(),
      repairTypesOptions: repairTypes?.map((repairType) =>
        repairType.getOption(),
      ),
      counterpartyOption: counterparty ? counterparty.getOption() : null,
      carOption: car ? car.getWithRelationsOption() : null,
      vit: car?.getVin(),
      brandModel: car?.getBrandModel()?.getTitle(),
      subdivision: car?.getSubdivision()?.getTitle(),
      files: repairOrder?.getFiles(),
      roadAccidentIds: roadAccidents?.map((roadAccidents) =>
        roadAccidents.getOptionWithLinkToRoadAccident(),
      ),
    }
  })

  return (
    <Form
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={canRepairOrdersUpdate}
      isView
    />
  )
}
