import { useCallback, useState } from 'react'
import { Media } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { IconButton } from '~/shared/ui/Buttons'
import { IconButtonProps } from '~/shared/ui/Buttons/IconButton'
import { PrintIcon } from '~/shared/ui/Icons'

type FileExportLinkProps = {
  filepath: string
  tooltipText: string
  iconButtonProps?: Partial<IconButtonProps>
}

export function FileExportLink({
  filepath,
  tooltipText,
  iconButtonProps,
}: FileExportLinkProps) {
  const [isLoading, setIsLoading] = useState<boolean>()

  const handleMediaGet = useCallback(async () => {
    try {
      setIsLoading(true)
      const blob = await Media.getMedia(filepath)

      window.open(URL.createObjectURL(blob), '_self')
    } catch (e) {
      logger.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [filepath])

  return (
    <IconButton
      {...iconButtonProps}
      tooltipProps={{ title: tooltipText }}
      onClick={handleMediaGet}
      loading={isLoading}
    >
      <PrintIcon />
    </IconButton>
  )
}
