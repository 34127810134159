import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionIcon from '@mui/icons-material/Description'
import { Grid, Stack, Typography } from '@mui/joy'
import { ThumbType } from '~/shared/api'
import { myPalette } from '~/shared/lib/theme'
import { IconButton } from '~/shared/ui/Buttons'
import { Img } from '~/shared/ui/Img'
import { FileWrapper, Item } from '../styled'
import { OpenThumb } from './OpenThumb'

type ThumbsProps = {
  onDelete?: (id: UniqueId) => void
  canDelete?: boolean
  thumbs: ThumbType[]
}

export function Thumbs({ onDelete, canDelete = true, thumbs }: ThumbsProps) {
  return (
    <Grid container spacing={2.5}>
      {thumbs.map((thumb) => (
        <Grid xs={4} key={`${thumb.previewUrlDownload}-${thumb.uuid}`}>
          <Item>
            <FileWrapper>
              {thumb.previewUrlDownload ? (
                <Img url={thumb.previewUrlDownload} />
              ) : (
                <DescriptionIcon sx={{ color: myPalette.green['700'] }} />
              )}
            </FileWrapper>

            <Typography
              sx={{
                width: '100%',
                margin: '0 8px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis !important',
                overflow: 'hidden !important',
                display: 'inline-block',
              }}
            >
              {thumb?.fileName}
            </Typography>

            <Stack direction='row' spacing={1}>
              <OpenThumb url={thumb.originalUrlDownload} />
              {canDelete && (
                <IconButton
                  onClick={() => onDelete?.(thumb.uuid)}
                  tooltipProps={{ title: 'Удалить' }}
                  loading={thumb.pending}
                >
                  <DeleteIcon sx={{ color: myPalette.red['700'] }} />
                </IconButton>
              )}
            </Stack>
          </Item>
        </Grid>
      ))}
    </Grid>
  )
}
