import * as z from 'zod'
import { ApiModel } from './core'

const schema = z.object({})

export type MediaAttribute = z.infer<typeof schema>

export class Media extends ApiModel<typeof schema, MediaAttribute> {
  static jsonApiType = ''

  static schema = schema

  static async getMedia(url: string): Promise<Blob> {
    const client = Media.httpClient.getImplementingClient()

    const response = await client.get(url, {
      responseType: 'blob',
    })

    return response?.data
  }
}
