import { Box, Stack } from '@mui/joy'
import { useGate, useStore } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { inspectionModel } from '~/entities/Inspection'
import { InspectionPhotoTypeEnum } from '~/shared/config/enums'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { FileExportLink } from '~/shared/ui/FileExport'
import { Gallery, mapPhotoToImage } from '~/shared/ui/Gallery'
import { GalleryImage } from '~/shared/ui/Gallery/type'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import RoutesTabs from '~/shared/ui/RoutesTabs'
import { useConfigTabs } from './useConfigTabs'

function ViewInspectionPage() {
  const configTabs = useConfigTabs()

  const params = useParams<{ inspectionId: string }>()
  const inspectionId = params.inspectionId as string

  useGate(inspectionModel.Gate, { inspectionId })

  const inspection = useStore(inspectionModel.$inspection)
  const isLoading = useStore(inspectionModel.requestInspectionFx.pending)

  const plateNumber = inspection?.getCar()?.getPlateNumber()
  const updatedAt = inspection?.getUpdatedAt()

  const title = `Осмотр [${plateNumber}] от ${
    updatedAt ? formatDateTimeForUI(updatedAt) : ''
  }`

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [{ title: 'Осмотры', link: '/inspections' }, { title }],
    [title],
  )

  const inspectionError = inspectionModel.useInspectionError(inspectionId)

  const driverSignature = inspection
    ?.getPhotos()
    .filter(({ type }) => type === InspectionPhotoTypeEnum.DRIVER_SIGNATURE)
    .map(mapPhotoToImage)
    .filter((photo): photo is GalleryImage => !!photo)

  const inspectionReportUrl = inspection?.getInspectionReportUrl()

  if (inspectionError) {
    return <NotFound message='Не удалось загрузить осмотр' />
  }

  return (
    <PageContainer title={title}>
      <Container isHeader>
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <Box>
            <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
            <PageTitle loading={isLoading}>{title}</PageTitle>
          </Box>
          <Stack direction='row' alignItems='center' spacing={2}>
            {!isLoading && inspectionReportUrl && (
              <FileExportLink
                filepath={inspectionReportUrl}
                tooltipText='Скачать АКТ осмотра'
                iconButtonProps={{
                  variant: 'gray',
                  loading: isLoading,
                }}
              />
            )}
            {Boolean(driverSignature?.length) && (
              <Gallery
                showList={false}
                images={driverSignature || []}
                buttonTitle='Подпись водителя'
              />
            )}
          </Stack>
        </Stack>
      </Container>

      <RoutesTabs
        parentPath={`/inspections/${inspectionId}`}
        config={configTabs}
      />
    </PageContainer>
  )
}

export default ViewInspectionPage
