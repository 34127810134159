import { ReactNode } from 'react'

export const identity = <ValueType>(value: ValueType): ValueType => value

type TimesIterateeFn<T> = (n: number) => T
export const times = <T = number>(
  n: number,
  iteratee: TimesIterateeFn<T> = (arg) => arg as T,
) => [...Array(n).keys()].map((n) => iteratee(n))

export const isString = (arg: unknown): arg is string => typeof arg === 'string'
export const isNumber = (arg: unknown): arg is number => typeof arg === 'number'

export const isObject = (arg: unknown): arg is object =>
  typeof arg === 'object' && arg !== null && !Array.isArray(arg)

export const isEmptyObject = (arg: unknown) =>
  isObject(arg) &&
  Object.entries(arg).filter(([, v]) => typeof v !== 'undefined').length === 0

export const getObject = <T>(arg: T) => (isObject(arg) ? arg : undefined)

export const sanitizePhoneNumber = (phone: string) =>
  phone.replace(/[(),\-_\s]/g, '')

export const cutStringBefore = (str: string, char: string) =>
  str.replace(new RegExp(`.*?${char}(.*)`), '$1')

export const cutStringAfter = (str: string, char: string) => str.split(char)[0]

export const sanitizeStringNumber = (price: string | number) =>
  parseFloat(String(price).replaceAll(' ', '').replace(',', '.'))

export const priceFormat = (price: number) => {
  return price.toLocaleString('ru-RU', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
}

export const priceRoundFormat = (price: number) => {
  return price.toLocaleString('ru-RU', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
}

export const mileageFormat = priceRoundFormat

export const decodeObjectParam = (
  value: string | (string | null)[] | null | undefined,
) => {
  if (!isString(value) || !value.startsWith('id-')) return undefined

  const obj = {
    id: cutStringBefore(cutStringAfter(value, '_label-'), 'id-'),
    label: cutStringBefore(value, '_label-'),
  }
  return obj.id ? obj : undefined
}

export const mapSearchParamsToFilters = (searchParams: URLSearchParams) => {
  return Object.fromEntries(
    [...searchParams]
      .map(([key, value]) => [key, decodeObjectParam(value)?.id || value])
      .filter(([, value]) => Boolean(value)),
  )
}

export const canShowObject = <T extends object>(isShow: boolean, object: T) =>
  isShow ? [object] : []

export const canShowElement = <T extends ReactNode>(
  isShow: boolean,
  element: T,
) => (isShow ? element : null)
