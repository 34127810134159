import { createDomain, sample } from 'effector'
import { useStore } from 'effector-react'
import { authStore, http, User, userOptionsStore } from '~/shared/api'
import { UserOptionsMe } from '~/shared/api/core/store/types'
import { pushFx } from '~/shared/lib/history'
import { sentryModel } from '~/shared/lib/sentry'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('entities.viewer')

/* Save token */
export const tokenSaved = domain.createEvent<string>()
export const saveTokenFx = domain.createEffect<string, void>({
  async handler(token) {
    authStore.saveToken(token)
  },
})
sample({
  clock: tokenSaved,
  target: saveTokenFx,
})

/* Clear token */
export const tokenCleared = domain.createEvent()
export const clearTokenFx = domain.createEffect({
  async handler() {
    authStore.clearToken()
  },
})
sample({
  clock: tokenCleared,
  target: clearTokenFx,
})

/* Save email */
export const emailSaved = domain.createEvent<string>()
export const saveEmailFx = domain.createEffect<string, void>({
  async handler(email) {
    authStore.saveEmail(email)
  },
})
sample({
  clock: emailSaved,
  target: saveEmailFx,
})

/* Clear email */
export const emailCleared = domain.createEvent()
export const clearEmailFx = domain.createEffect({
  async handler() {
    authStore.clearEmail()
  },
})
sample({
  clock: emailCleared,
  target: clearEmailFx,
})

/* Save currentUserId */
export const currentUserIdSaved = domain.createEvent<string>()
export const saveCurrentUserIdFx = domain.createEffect<string, void>({
  async handler(currentUserId) {
    authStore.saveCurrentUserId(currentUserId)
  },
})
sample({
  clock: currentUserIdSaved,
  target: saveCurrentUserIdFx,
})

/* Clear currentUserId */
export const currentUserIdCleared = domain.createEvent()
export const clearCurrentUserIdFx = domain.createEffect({
  async handler() {
    authStore.clearCurrentUserId()
  },
})
sample({
  clock: currentUserIdCleared,
  target: clearCurrentUserIdFx,
})

/* Save user options */
export const userOptionsSave = domain.createEvent<UserOptionsMe>()
export const saveUserOptionsFx = domain.createEffect<UserOptionsMe, void>({
  async handler(options) {
    userOptionsStore.save(options)
  },
})
sample({
  clock: userOptionsSave,
  target: saveUserOptionsFx,
})

/* Clear user options */
export const userOptionsCleared = domain.createEvent()
export const clearUserOptionsFx = domain.createEffect({
  async handler() {
    userOptionsStore.clear()
  },
})
sample({
  clock: userOptionsCleared,
  target: clearUserOptionsFx,
})

http.onUnauthorize(() => {
  tokenCleared()
  userOptionsCleared()
})

/* Token */
export const $token = domain
  .createStore<string | null>(authStore.token)
  .on(tokenSaved, (_, token) => token)
  .on(tokenCleared, () => null)

/* Email */
export const $email = domain
  .createStore<string | null>(authStore.email)
  .on(emailSaved, (_, email) => email)
  .on(emailCleared, () => null)

/* CurrentUserId */
export const $currentUserId = domain
  .createStore<string | null>(authStore.currentUserId)
  .on(currentUserIdSaved, (_, currentUserId) => currentUserId)
  .on(currentUserIdCleared, () => null)

export const $authenticated = $token.map<boolean>((token) => Boolean(token))

/* Sign-in/Sign-out */
export const signIn = domain.createEvent()
sample({
  clock: signIn,
  fn() {
    return '/login'
  },
  target: pushFx,
})

export const signOut = domain.createEvent()
export const logoutFx = domain.createEffect({
  async handler() {
    return await User.logout()
  },
})

sample({
  clock: signOut,
  target: logoutFx,
})

sample({
  clock: logoutFx.doneData,
  target: [
    tokenCleared,
    emailCleared,
    currentUserIdCleared,
    userOptionsCleared,
    sentryModel.clearUser,
  ],
})

/* User data */
export const fetchUserFx = domain.createEffect<UniqueId, User | null>({
  async handler(id) {
    const response = await User.with('regions').with('roles').find(id)
    return response.getData() as User
  },
})

export const userRequested = domain.createEvent()
sample({
  clock: userRequested,
  source: $currentUserId,
  filter: isString,
  target: fetchUserFx,
})

export const $user = domain
  .createStore<User | null>(null)
  .on([fetchUserFx.doneData], (_, user) => user)

/* Hooks */
export const useAuthenticated = () => useStore($authenticated)
