import { CircularProgress } from '@mui/joy'
import { useCallback, useEffect, useState } from 'react'
import { Media } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { Wrapper } from './styled'

type ImgProps = {
  url: string
}

export function Img({ url }: ImgProps) {
  const [isLoading, setIsLoading] = useState<boolean>()
  const [imageUrl, setImageUrl] = useState<string>()

  const handleMediaGet = useCallback(async () => {
    try {
      setIsLoading(true)
      const blob = await Media.getMedia(url)

      setImageUrl(URL.createObjectURL(blob))
    } catch (e) {
      logger.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [url])

  useEffect(() => {
    handleMediaGet()
    // eslint-disable-next-line
  }, [url])

  return (
    <Wrapper>
      {isLoading && <CircularProgress size='sm' />}
      {!isLoading && imageUrl && (
        <img src={imageUrl} width='100%' height='auto' alt='img' />
      )}
    </Wrapper>
  )
}
